:root {
  --color-default: #d2d2d2;
  --color-default-light: #e6e6e6;
  --color-default-dark: #ada8a8;
  --color-transparent: rgba(255, 255, 255, 0.001);
  --font-size-default: 15px;
}

$border-radius: 5px;
html,
body,
#root {
  min-height: 100%;
  background: #f0f3f5;
}
body {
  margin: 0;
  font-family: Satoshi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: var(--font-size-default) !important;
  &.no-scroll {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}

.btn-group {
  display: flex;
  flex-direction: column;
}

.card-header {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  color: white;
}
.breadcrumb-custom {
  background: #299af3;

  .col {
    height: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #aad2f3;
      border-right: 13px solid #c0e3ff;
      transform-origin: 100% 100%;
      transform: skewX(30deg);
      transition: 1s;
    }

    span {
      color: white;
      position: absolute;
      line-height: 25px;
      left: 3%;
    }

    .active {
      &:before {
        background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
        border-right: 13px solid rgb(107, 190, 255);
      }
    }
  }
}

.load_ls_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.Toastify__toast {
  font-family: Satoshi !important;
  border-radius: 5px !important;
  min-height: 40px !important;
}
@import "./variables.scss";

.Toastify__toast--error {
  background: #dc7c7c;
}
.Toastify__toast--success {
  background: #36767e;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  overflow: hidden;
  .card-header {
    text-align: center;
  }
}
.navbar-brand {
  font-size: 18px;
}
.login-sidenav::before {
  content: "";
  //background-image: url(/images/main-bg.jpeg);
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.auth-container::before {
  content: "";
  //background-image: url(/images/logo_small.svg);
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 118%;
  background-position: 48% 40%;
  filter: grayscale(1);
  opacity: 0.03;
  left: 0;
}

.table {
  background: white;
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  overflow: hidden;

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  .tr-hover {
    cursor: pointer;
    td {
      background: white;
    }
  }
}

.form-text {
  text-align: center;
}

.modifyInputBtn {
  opacity: 0.4;
  top: 27px;
  right: 0px;
  height: 39px;
  padding: 0 12px;
}
.emailContainer:hover .modifyInputBtn {
  opacity: 1;
}
.resendMail {
  .overinfo {
    display: none;
    text-transform: none !important;
  }

  &:hover,
  &.isactive {
    .overinfo {
      display: inline-block;
    }
  }
}

.highlight {
  background: linear-gradient(90deg, #ffffce 80%, transparent);
}
.highlight-shadow {
  border-left: 4px solid #2196f3;
}

.acc-btn:hover {
  background: rgba(128, 128, 128, 0.01) !important;
}
.grecaptcha-badge {
  opacity: 0;
}

.ck-content {
  min-height: 300px;
}
.bg-default {
  background: #f0f3f5;
}
.text-grey {
  color: grey;
}

.read-toogle:hover .read-tooltip {
  opacity: 1;
  pointer-events: auto;
}

.read-tooltip {
  position: absolute;
  right: 0px;
  top: -25px;
  padding: 0px 10px;
  background-color: #ededed;
  text-align: center;
  border-radius: 20px;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  border: 1px solid #cacaca;
  transition: opacity 0.2s ease-in-out;
}
.hover-color-success:hover {
  color: var(--color-success);
}
.comment-tooltip-button {
  position: relative;
}
.comment-tooltip-button:hover .comment-tooltip {
  display: block !important;
}
.comment-tooltip {
  display: none !important;
  position: absolute;
  top: -28px;
  white-space: nowrap;
  right: 0;
  background: white;
  padding: 5px;
}

.status-tooltip {
  position: relative;
}
.status-tooltip:hover .status-tooltip-content {
  display: block;
}
.status-tooltip-content {
  display: none;

  position: absolute;
  top: 100%;
  right: 0;
  top: 20px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.lead-status-selector {
  @media (max-width: 768px) {
    gap: 5px;
  }
}

.lead-status-column {
  @media (max-width: 768px) {
    position: relative;

    // border-radius: 0px !important;
    // max-width: 300px;
    border-radius: 0px !important;
  }
}

.generic-tooltip {
  position: relative;
}
.generic-tooltip:hover .generic-tooltip-content {
  display: block;
}
.generic-tooltip-content {
  display: none;

  position: absolute;
  top: 100%;
  right: 0;
  top: 20px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.Toastify__toast-container {
  z-index: 999999999 !important;
}
