.rbeListItem {
  max-height: 300px;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .rbeListItem {
    max-height: none;
    overflow-x: auto;
  }
}
