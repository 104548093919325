.linkedLeads {
  position: absolute;
  top: -27px;
  z-index: 9;
  right: 10px;
  padding: 0px 0px;
  background-color: #ffffff;

  & button.btn-sm {
    font-weight: 400;
  }
}

// @media (max-width: 768px) {
//   .linkedLeads {
//     position: static;
//     margin-right: 0px;
//     border-top-left-radius: 0px;
//     border-top-right-radius: 0px;
//     border: 0px !important;
//     justify-content: start;
//     text-align: left !important;
//     align-items: center;
//     padding-left: 10px;
//     margin-right: -15px;
//     margin-left: -15px;
//     width: auto !important;

//     & .linkedToText {
//       margin-left: 0px !important;
//     }
//   }
// }
