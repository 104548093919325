.topBadge {
  position: absolute;
  top: -34px;
  background: white;
  color: black;
  padding: 3px 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  &.right {
    right: 10px;
  }

  &.left {
    left: 10px;
  }
}

@media (max-width: 768px) {
  .topBadge {
    position: static;
    border: 0px !important;
    text-align: center;
    width: 100%;
    justify-content: start;
    padding-left: 15px;
  }
}
