@import "./variables.scss";
html {
  scroll-behavior: smooth;
}
.btn-sm {
  padding: 4px 1rem !important;
  border: 1px solid #dee2e6;
  font-weight: 400 !important;
}
.btn {
  border-radius: $border-radius;
  text-transform: none;
  font-weight: 700;
  font-size: var(--font-size-default);
  box-shadow: none;
  font-family: Satoshi;

  .btn-sm {
    min-height: 30px;
    padding: 4px 1rem !important;
    margin-bottom: 0;
    line-height: 20px;
  }

  .btn-xsm {
    min-height: 0 !important;
    height: 22px !important;
    padding: 0 6px !important;
    line-height: 20px !important;
    text-transform: none !important;
    font-weight: 600;
  }

  .active .fa {
    line-height: 17px;
    color: $color-secondary;
    padding: 7px 10px;
    border-radius: 100%;
    background: white;
    font-size: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus,
  .focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.btn-event.active {
  background: $color-success !important;
  color: white !important;
}

.btn-hoverable .hidden-hover {
  display: none;
}
.btn-hoverable:hover .hidden-hover {
  display: inline-block;
}

//PRIMARY
.btn-primary,
.btn-primary:focus,
.card-header,
.navbar {
  background: $color-primary;
}
.btn-primary:hover {
  background: $color-primary-light;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: $color-primary-dark;
}
.btn-outline-primary {
  color: $color-success;
  border: 1px solid $color-success;
  background: #f3f3ef;
}

//SECONDARY
.btn-secondary {
  background: $color-secondary;
}
.btn-secondary:hover {
  background: $color-secondary-light;
}
.btn-secondary:focus {
  background: $color-secondary-dark;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background: $color-secondary-dark;
}

//DEFAULT
.btn-default {
  background: #fafafa;
}
.btn-default:hover {
  background: $color-default-dark;
}
.btn-default:focus {
  background: $color-default-dark;
}
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background: #3e75ff;
  color: white;
}

//SUCCESS
.btn-success {
  background: $color-success;
}
.btn-success:hover {
  background: $color-success-light;
}
.btn-success:focus {
  background-color: $color-success-dark;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background: $color-success-dark;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background: $color-success-dark !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: $color-success-light;
  border-color: $color-success-light;
  background-image: none;
}

//INFO
.btn-info {
  background: $color-info;
}
.btn-info:hover {
  background: $color-info-light;
}
.btn-info:focus {
  background: $color-info-dark;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background: $color-info-dark;
}

//DANGER
.btn-danger {
  background: $color-danger;
}
.btn-danger:hover {
  background: $color-danger-light;
}
.btn-danger:focus {
  background: $color-danger-dark;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background: $color-danger-dark;
}

//WARNING
.btn-warning {
  background: $color-warning;
}
.btn-warning:hover {
  background: $color-warning-light;
}
.btn-warning:focus {
  background: $color-warning-dark;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  background: $color-warning-dark;
}

//LINK
.btn-link {
  color: $color-primary;
}
.btn-link:hover {
  color: $color-primary;
}
.btn-link:not(:disabled):not(.disabled):active,
.btn-link:not(:disabled):not(.disabled).active,
.show > .btn-link.dropdown-toggle {
  color: $color-primary;
}

//DARK
.btn-dark {
  background: $color-dark;
}
.btn-dark:hover {
  background: $color-dark-light;
}
.btn-dark:focus {
  background: $color-dark-dark;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  background: $color-dark;
}

//LIGHT
.btn-light {
  background: $color-light;
}
.btn-light:hover {
  background: $color-light-light;
}
.btn-light:focus {
  background: #eeeeee;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  background: #040da5;
  color: white;
}

.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  // background: white;
  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-default);
  text-transform: none;
  border-radius: 5px !important;
  flex: 1;
}
.big-shadow-hover {
  &:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.spec-avatar {
  transition: 300ms;
  &:hover {
    transform: scale(1.1);
  }
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #3d75fe;
  color: white;
  cursor: pointer;
  padding: 6px 13px;
  border-radius: 12px;
  font-size: 18px;
}

#myBtn:hover {
  background-color: #060ca5;
}
button {
  transition: all 0.2s ease-in-out;
}

button:not(:disabled):active {
  transform: translateY(1px);
}
#dropdownMenuButton::before,
#dropdownMenuButton::after {
  display: none;
}
