@import "./variables.scss";

.bg-primary {
  background-color: $color-primary !important;
}
.bg-success {
  background-color: $color-success !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}
.bg-info {
  background-color: $color-info !important;
}
.bg-tertiary {
  background-color: $color-tertiary !important;
}

.text-primary {
  color: $color-primary !important;
}
.text-success {
  color: $color-success !important;
}
.text-info {
  color: $color-info !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-dark {
  color: $color-dark !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.alert {
  font-weight: 600;
}
.alert-light {
  background: $color-light !important;
}
.alert-success {
  background: $color-success !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: $color-success !important;
}
.nav-tabs .nav-link {
  margin-right: 5px;
  border-radius: 0px;
  color: $color-primary !important;
  font-weight: 500;
  font-size: 0.9rem;
  box-shadow: none;
  border: 1px solid $color-primary;

  &:hover {
    border: 1px solid !important;
  }

  &.active {
    border: 1px solid;
  }
}
// .nav-tabs .nav-item:first-child .nav-link {
//   border-top-left-radius: $border-radius !important;
//   border-bottom-left-radius: $border-radius !important;
// }
// .nav-tabs .nav-item:last-child .nav-link {
//   border-top-right-radius: $border-radius !important;
//   border-bottom-right-radius: $border-radius !important;
// }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $color-primary;
  background: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus {
  box-shadow: none;
  background: $color-primary;
  color: white !important;
}
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus:hover {
  background: $color-primary;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus:hover {
  box-shadow: none;
  background-color: white;
  border-bottom: 1px solid;
}

.ac-results div {
  background-color: $color-primary;
  color: white;
}
.ac-results div:hover {
  background-color: $color-success;
}

.dashed-border:focus,
.dashed-border:hover {
  outline: none;
  color: $color-primary;
  cursor: pointer;
}

.badge-tertiary {
  background-color: $color-success !important;
}

.badge-orange{
  background-color: $color-partenaire !important;
}
