@import "./variables.scss";

.form-control {
  box-shadow: none !important;
  border-radius: 5px;
  padding: 10px !important;
  padding-right: 15px !important;
  height: 48px;
  background: white;
  border: 1px solid #dee2e6 !important;
  height: 40px;
  font-weight: 600;
  color: black;
  border: none;
  color: $color-primary;
  font-size: 0.9rem;
  line-height: normal;

  &:focus {
    border-color: $color-success !important;
    background-color: white;
    color: black;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

label {
  color: $color-success;
  font-size: var(--font-size-default);
  line-height: 18px;
  &:not(.mantine-Switch-label, .mantine-SegmentedControl-label)  {
    margin-top: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    margin-bottom: 3px;
    margin-top: 5px;
  }

  &.mantine-SegmentedControl-label {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.readonly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  label {
    width: 30%;
    margin: 0;
  }

  .input-group {
    width: 70%;
  }
  .form-control {
    background: transparent !important;

    box-shadow: none !important;
    width: 100%;
  }
}
.bg-blue {
  background: #f1f2fe;
}
.input-group .input-group-append {
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 12px;
  border: none;
  display: flex;
  align-items: center;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid $color-success;
  background-color: white;
}

textarea[disabled],
textarea[disabled]::placeholder,
textarea.form-control[disabled],
textarea.form-control[disabled]::placeholder,
input.form-control[disabled],
input.form-control[disabled]::placeholder,
input[type="text"][disabled],
input[type="text"][disabled]::placeholder,
input[type="password"][disabled],
input[type="password"][disabled]::placeholder,
input[type="email"][disabled],
input[type="email"][disabled]::placeholder,
input[type="number"][disabled],
input[type="number"][disabled]::placeholder,
[type="text"].form-control[disabled],
[type="text"].form-control[disabled]::placeholder,
[type="password"].form-control[disabled],
[type="password"].form-control[disabled]::placeholder,
[type="email"].form-control[disabled],
[type="email"].form-control[disabled]::placeholder,
[type="tel"].form-control[disabled],
[type="tel"].form-control[disabled]::placeholder,
[contenteditable].form-control[disabled],
[contenteditable].form-control[disabled]::placeholder {
  color: #3e75ff;
  border-bottom: 0;
}

.DateInput_input {
  font-weight: 600 !important;
  box-shadow: none !important;
  border: 1px solid #3e75ff !important;
  border-radius: 10px !important;
  font-size: 0.9rem !important;
  padding: 5px 10px !important;
  color: #3e75ff !important;

  &:focus {
    color: black !important;
  }
}
.custom-control-label {
  text-indent: 0px;
}
.custom-control.custom-switch {
}

// .custom-switch .custom-control-label::before {
//   left: -49px;
//   pointer-events: all;
//   top: -3px;
//   background: #bbb;
//   border: none;
//   opacity: 1 !important;
//   height: 23px;
//   border-radius: 30px;
//   width: 36px;
// }
// .custom-switch .custom-control-label::after {
//   width: 16px;
//   height: 16px;
//   left: -48px;
// }

// .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//   background-color: white;
//   left: -41px;
// }
// .custom-control-input:checked ~ .custom-control-label::before {
//   color: #fff;
//   border-color: $color-success;
//   background: $color-success;
// }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $color-success;
}
input[type="checkbox"] {
  width: 22px;
  height: 22px;
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
}
input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.filter-container {
  // border-left: 4px solid #130da5;
  .form-group {
    margin-bottom: 10px;
  }
  .label-text {
    font-size: var(--font-size-default);
    line-height: 16px;
  }
  .form-control {
    padding: 3px 5px !important;
    height: 32px;
  }
}
