@import "../../../variables";

.navbar {
  padding: 12px 10px !important;
  background: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: none;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
  &.menu-widg {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  max-width: 60px;
  width: 60px;
  //transform: rotateY(100deg);
  transition: all 0.4s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
  position: fixed;
  max-height: 100vh;
  height: 100vh;
  overflow: auto;
  // background: linear-gradient(180deg, #0a107d 54px, #020763 40px);
  // background: #0a107d;
  background: white;
  z-index: 99999999;

  & .sidebar-avatar {
    transform: scale(0.7);
    span {
      display: none;
    }
  }

  & .sidebar-item {
    & .nav-link {
      white-space: nowrap;
    }
    span {
      visibility: hidden;
      white-space: nowrap;
    }
  }

  &:hover {
    width: 280px;
    max-width: 250px;

    & .sidebar-avatar {
      transform: scale(1);
    }

    & .sidebar-item {
      span {
        visibility: visible;
      }
    }
  }

  .sidebar-header {
    padding: 20px;
    position: relative;
    color: white;

    a:hover {
      animation-iteration-count: infinite;
    }
  }
}

#sidebar ul.components {
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a,
#sidebar ul li summary {
  color: #0a107d;
  padding: 10px;
  font-size: 1em;
  display: block;
  font-weight: 500;
}
details summary::-webkit-details-marker {
  display: none;
}
#sidebar ul li summary {
  color: #767676;
}
#sidebar ul li a:hover,
#sidebar ul li a.active {
  background: $color-secondary;
  color: white;
}
#sidebar ul li a:hover {
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;

  &.connected {
    padding-left: 60px;
  }
}

#sidebarCollapse,
#sidebarOpen {
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  display: none;
}

#sidebarCollapse span:first-of-type,
#sidebarOpen span:first-of-type {
  transform: rotate(45deg) translate(8px, 9px);
}
#sidebarCollapse span:nth-of-type(2),
#sidebarOpen span:nth-of-type(2) {
  opacity: 0;
}
#sidebarCollapse span:last-of-type,
#sidebarOpen span:last-of-type {
  transform: rotate(-45deg) translate(4px, -6px);
}

#sidebarCollapse.active span,
#sidebarOpen.active span {
  transform: none;
  opacity: 1;
  margin: 5px auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebarCollapse,
  #sidebarOpen {
    display: block;
  }
  #content.connected {
    padding-left: 0px;
  }
  #sidebar {
    width: 0px !important;

    & .sidebar-header {
      padding: 0;
    }
    & .sidebar-avatar {
      transform: scale(1);
      span {
        display: block;
      }
    }
  }
  #sidebar.active {
    margin-left: 0;
    transform: none;
    width: 100vw !important;
    position: fixed;
    z-index: 999999999;
    left: 0;
    display: block;
    max-width: none;

    & .sidebar-items-container {
      max-height: calc(100vh - 124px);
      overflow: auto;
    }

    & .sidebar-item span {
      visibility: visible;
    }
  }
}
