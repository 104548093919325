@import "./variables.scss";

@media (max-width: 1300px) {
  .acc-btn {
    flex-direction: column;
  }
  .user-title-container {
    .user-title-header {
      // display: block !important;
      flex-direction: column !important;
      width: 100%;
      width: 100%;
      text-align: center !important;

      .user-title-icon {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: row !important;
        justify-content: center;
        margin-right: 0 !important;
        .fa {
          margin-right: 5px;
        }
      }
      .user-title-info {
        text-align: center !important;
        width: 92%;
      }
    }
    .user-title-content {
      justify-content: center !important;
      width: 100%;
      margin: 10px 0;
    }
  }
}
@media (max-width: 900px) {
  html,
  body {
    font-size: 12px;

    .form-control {
      font-size: 12px;
    }
  }

  .nav-tabs .nav-item {
    width: 100% !important;
  }
  .overlay-bottom {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .readonly .input-group {
    width: 100%;
  }
  .readonly label {
    width: 100%;
    margin-top: 10px;
  }
  .createdAt {
    margin-right: -30px;
    font-size: 11px;
  }
  .assignTo {
    margin-left: -80px;
  }
  .linkedTo {
    left: 100px;
    width: 190px;
    padding-left: -30px;
    text-align: left;
  }
  .linkedToText {
    font-size: 10px;
    margin-left: -50px;
  }
  .amount-component {
    .amount-label {
      font-size: 18px !important;
    }
    span {
      font-size: 18px !important;
    }
  }
}
