.createdAt {
  // position: absolute;
  // top: -48px;
  // right: 20px;
  // padding: 0px 10px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .createdAt {
    position: static;

    margin-right: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0px !important;
    justify-content: start;
    text-align: left !important;
    align-items: start;
  }
}
