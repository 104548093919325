.todoOwnership {
  position: absolute;
  top: -22px;
  right: 5px;
  z-index: 9;
  background: white;
  padding: 0 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

@media (max-width: 768px) {
  .todoOwnership {
    position: static;
    border: 0px !important;
  }
}
