.assignTo {
  // position: absolute;
  // top: -48px;
  // left: 69px;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & .check-btn-list {
    flex-direction: column !important;
    padding-right: 15px;
  }
  & .c-button-item {
    font-size: 10px;
    background-color: rgba(255, 255, 255, 001) !important;
    padding: 3px 10px !important;
    margin: 0px !important;
    align-items: flex-start !important;
    font-weight: 500 !important;

    & .check-icon {
      top: 3px !important;
      right: -7px !important;
      font-size: 7px !important;
      background-color: rgba(255, 255, 255, 001) !important;
      color: black !important;
      box-shadow: none !important;
    }
  }
}

@media (max-width: 768px) {
  .assignTo {
    position: static;
    margin-left: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0px !important;
    justify-content: start;
  }
}
